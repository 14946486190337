import Vue from "vue";
import App from "./App.vue";
import vSelect from "vue-select";
import VueRouter from "vue-router";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Vuex from "vuex";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

require("dotenv").config();

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(IconsPlugin);
Vue.use(VueLoading);
Vue.use(Vuex);

Vue.component("v-slider", VueSlider);
Vue.component("v-select", vSelect);
new Vue({
  render: (h) => h(App),
  router: router,
  store: store,
}).$mount("#app");
