<template>
  <div>
    <Header />
    <loading :active.sync="isLoading" :is-full-page="fullPage" loader="bars" />
    <router-view :key="$route.fullPath" @startLoad="updateLoading" />
    <Footer />
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  data: function() {
    return {
      isLoading: false,
      fullPage: true,
    };
  },
  components: { Loading, Header, Footer },
  methods: {
    updateLoading(loading) {
      this.isLoading = loading;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;700&display=swap");

body {
  padding: 0;
  margin: 0;
  font-family: "Readex Pro", sans-serif !important;
  font-size: 14px !important;
  --vs-font-size: 0.9rem;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: white;
  border: none;
  color: black;
}

.style-chooser-input {
  color: #323232;
  background: #dfe5fb;
}

.style-chooser .vs__search,
.style-chooser .vs__open,
.style-chooser .vs__dropdown-menu {
  min-width: 60px;
  white-space: nowrap;
  color: #323232;
}

.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__open,
.style-chooser .vs__dropdown-menu {
  border: 1px solid rgba(50, 50, 50, 0.4);
}

.style-chooser .vs__open-indicator > path {
  fill: rgba(50, 50, 50, 0.4);
}

.is-invalid > div > div > .vs__dropdown-toggle {
  border: 1px solid #dc3545;
}

.is-invalid > div > div > div > .vs__actions > .vs__open-indicator > path {
  fill: #dc3545;
}

.is-valid > div > div > .vs__dropdown-toggle {
  border: 1px solid #198754;
}

.is-valid > div > div > div > .vs__actions > .vs__open-indicator > path {
  fill: #198754;
}

.style-chooser .vs__selected {
  color: #323232;
}

.style-chooser .vs__dropdown-menu > * {
  color: #323232;
  background: white;
}

.style-chooser .vs__dropdown-menu > *:hover {
  background: white;
  font-weight: bold;
}

.v-select {
  min-width: 60px;
}

.vs__search,
.vs__search:focus {
  margin-top: 3px;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #e5e5e5;
}

.form-control:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6) !important;
  border-color: rgba(0, 0, 0, 1) !important;
}

.page-item.active .page-link {
  background-color: black !important;
  border-color: black !important;
}

.page-item .page-link {
  color: black;
}

.page-link:focus {
  z-index: 3;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25) !important;
}

.custom-control-label > span {
  padding-left: 5px;
}

.middle {
  vertical-align: middle;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.flag {
  border: 1px solid #cccccc;
  border-radius: 3px;
}

.nav-item > ul {
  border: 1px solid #e9ecef;
  background: rgba(33, 37, 41, 0.95);
}

.nav-item > ul > li > a:hover {
  background: #5e5f6956;
  color: white;
}
.nav-item > ul > li > a {
  color: #e9ecef;
}

.map_image {
  border: 1px solid #cccccc;
  border-radius: 7px;
}

.hack {
  border-radius: 3px;
  padding: 0 2px;
  margin: 0 2px;
  font-weight: bold;
  white-space: nowrap;
  padding-right: 6px;
  padding-left: 6px;
  user-select: none;
}

.hack.oh {
  background-color: #bff579;
}

.hack.ah {
  background-color: #ff4c4c;
}

.hack.wh {
  background-color: #33ccff;
}

.vb {
  background-color: #ff0000;
}

.gb {
  background-color: #ff6666;
}

.vdp-datepicker__calendar > div > .cell.day-header:hover,
.vdp-datepicker__calendar > div > .cell.day:hover {
  border: 1px solid #323232 !important;
}

.vdp-datepicker__calendar > div > .cell.selected,
.vdp-datepicker__calendar > div > .cell.selected:hover {
  color: white;
  background-color: #323232;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background: #323232;
}

::selection {
  background: #323232;
  color: white;
}
</style>
