import Vue from "vue";
import Toasted from "vue-toasted";

Vue.use(Toasted);

export default {
  successToast(message) {
    return Vue.toasted.show(message, {
      position: "top-center",
      duration: 5000,
      type: "success",
      action: {
        text: "x",
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      },
    });
  },
  errorToast(message) {
    return Vue.toasted.show(message, {
      position: "top-center",
      duration: 5000,
      type: "error",
      action: {
        text: "x",
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      },
    });
  },
  infoToast(message) {
    return Vue.toasted.show(message, {
      position: "top-center",
      duration: 5000,
      type: "info",
      action: {
        text: "x",
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      },
    });
  },
};
