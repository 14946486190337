import VueRouter from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/components/App"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/banned",
    name: "Home Banned",
    component: () => import("@/components/AppBanned"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/components/Login"),
  },
  {
    path: "/stats",
    name: "Stats",
    component: () => import("@/components/Stats"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(
  (to, from, next) => {
    store.dispatch("isLogged").then((res) => {
      if (to.name === "Login") {
        next(); // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
      } else if (to.meta && to.meta.requiresAuth === false) {
        next(); // requires auth is explicitly set to false
      } else if (res.data == "true") {
        next();
      } else next({ name: "Login" });
    });
  }
  // requires auth is explicitly set to false
);

export default router;
