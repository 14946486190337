<template>
  <footer id="sticky-footer" class="flex-shrink-0 py-4 bg-dark text-white-50 fixed-bottom">
    <div class="container text-center">
      <small>Copyright &copy; {{ copyright }}, All rights reserved.</small>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data: function() {
    return {
      copyright: "Davide Chiapperini",
    };
  },

  mounted: function() {},

  computed: {},

  methods: {},
};
</script>
<style scoped></style>
