<template>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <div class="container">
      <svg xmlns="http://www.w3.org/2000/svg" id="logo" width="100" height="70" viewBox="0 0 70 64">
        <path
          fill="#FFFFFF"
          d="M74.941 29.612a4.014 4.014 0 0 1-4.012 4.013c-2.216 0-3.988-1.797-3.988-4.013a4.012 4.012 0 0 1 8.026 0h-.026zm-4.037-7.458a7.442 7.442 0 0 0-7.458 7.409l-4.652 6.671c-.196-.025-.394-.025-.566-.025a5.646 5.646 0 0 0-2.855.788l-21.07-8.468a5.57 5.57 0 0 0-5.44-4.382 5.599 5.599 0 0 0-5.587 5.588 5.6 5.6 0 0 0 5.587 5.588 5.646 5.646 0 0 0 2.855-.788l21.07 8.468a5.57 5.57 0 0 0 5.44 4.382c2.88 0 5.269-2.215 5.538-5.021l7.164-5.243a7.453 7.453 0 0 0 7.458-7.458 7.453 7.453 0 0 0-7.458-7.458l-.026-.051zm0 2.461a5 5 0 0 1 4.997 4.997 5 5 0 0 1-4.997 4.997 5 5 0 0 1-4.996-4.997 5 5 0 0 1 4.996-4.997zm-42.067.985c1.575 0 2.929.886 3.619 2.166l-2.043-.812c-1.649-.591-3.471.221-4.11 1.846-.666 1.625.097 3.471 1.697 4.184l1.723.689a3.616 3.616 0 0 1-.886.099 4.103 4.103 0 0 1-4.111-4.111 4.103 4.103 0 0 1 4.111-4.111v.05zm29.366 12.062a4.101 4.101 0 0 1 4.111 4.11 4.102 4.102 0 0 1-4.111 4.11 4.055 4.055 0 0 1-3.619-2.19c.665.271 1.354.542 2.02.812a3.302 3.302 0 0 0 4.258-1.798 3.256 3.256 0 0 0-1.821-4.233l-1.698-.688c.295-.074.59-.1.886-.1l-.026-.023z"
        />
      </svg>
      <b-navbar-brand href="#" class="d-flex flex-column mx-4">
        <span class="title"> Steam VAC Checker</span>
        <span class="subtitle"> The #1 VAC Checker in the world</span>
      </b-navbar-brand>
      <b-navbar-toggle target="navbarResponsive"><span class="navbar-toggler-icon"></span></b-navbar-toggle>
      <b-collapse id="navbarResponsive" is-nav>
        <b-navbar-nav class="ms-auto" v-if="$route.name != 'Login'">
          <b-nav-item class="mx-3" :active="$route.name === 'Home'" :to="{ name: 'Home' }"><b-icon-house-fill></b-icon-house-fill> Home</b-nav-item>
          <b-nav-item class="mx-3" :active="$route.name === 'Stats'" :to="{ name: 'Stats' }"><b-icon-graph-up></b-icon-graph-up> Stats</b-nav-item>

          <b-nav-item-dropdown class="mx-3" right>
            <template slot="button-content">
              <b-icon-person-fill></b-icon-person-fill>
              {{ $store.state.user }}
            </template>
            <b-dropdown-item @click="checkBans"><b-icon-arrow-clockwise animation="spin-reverse"></b-icon-arrow-clockwise> Check Bans</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'Home Banned' }"><b-icon-person-fill></b-icon-person-fill> Banned Players</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="logout" href="#"><b-icon-door-open-fill></b-icon-door-open-fill> Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import SteamService from "../services/SteamService";
import ToastService from "../services/ToastService";
export default {
  name: "Header",
  data: function() {
    return {
      hideMenu: false,
      tab: 0,
    };
  },

  computed: {},

  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push({ name: "Login" });
    },

    checkBans() {
      ToastService.infoToast("Checking, please wait!");
      SteamService.checkBans()
        .then((res) => {
          console.log(res);
          if (res.ok == true) ToastService.successToast("Bans updated");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.subtitle {
  font-size: 13px;
}

.title {
  font-size: 25px;
  font-weight: bold;
}
</style>
