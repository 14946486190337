import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({ key: "state", paths: ["user", "token"] })],
  state: {
    user: null,
    token: null,
    logged: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setLogged(state, logged) {
      state.logged = logged;
    },
    logout(state) {
      state.user = null;
      state.token = null;
    },
  },
  actions: {
    isLogged({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .post("https://steambanchecker.altervista.org/checkLogin.php", { username: state.user, token: state.token })
          .then((res) => {
            commit("setLogged", res.data);

            resolve(res);
          })
          .catch((error) => {
            commit("setLogged", false);
            reject(error);
          });
      });
    },
  },
  getters: {
    async isLoggedIn(state) {
      return state.logged;
    },
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.token;
    },
  },
});
