import axios from "axios";

export default {
  async getPlayers(page, search) {
    return axios.get("https://steambanchecker.altervista.org/getPlayers.php?page=" + page + "&search=" + search).then((response) => {
      return response.data;
    });
  },
  async checkBans() {
    return axios.get("https://steambanchecker.altervista.org/checkBans.php").then((response) => {
      return response.data;
    });
  },
  async getPlayersBanned(page, search) {
    return axios.get("https://steambanchecker.altervista.org/getPlayersBanned.php?page=" + page + "&search=" + search).then((response) => {
      return response.data;
    });
  },
  async getPlayersBannedToday() {
    return axios.get("https://steambanchecker.altervista.org/getPlayersBannedToday.php").then((response) => {
      return response.data;
    });
  },
  async getPlayersBannedMonthly() {
    return axios.get("https://steambanchecker.altervista.org/getPlayersBannedMonthly.php").then((response) => {
      return response.data;
    });
  },
  async getPlayersBannedYearly() {
    return axios.get("https://steambanchecker.altervista.org/getPlayersBannedYearly.php").then((response) => {
      return response.data;
    });
  },
  async getPlayersBanned30() {
    return axios.get("https://steambanchecker.altervista.org/getPlayersBanned30.php").then((response) => {
      return response.data;
    });
  },
  async getMaps() {
    return axios.get("https://steambanchecker.altervista.org/getMaps.php").then((response) => {
      return response.data;
    });
  },
  async getMapsStats() {
    return axios.get("https://steambanchecker.altervista.org/getMapsStats.php").then((response) => {
      return response.data;
    });
  },
  async isAuth() {
    return axios.get("https://steambanchecker.altervista.org/auth.php").then((response) => {
      return response.data;
    });
  },
  async login(username, password) {
    return axios.post("https://steambanchecker.altervista.org/login.php", { username: username, password: password }).then((response) => {
      return response.data;
    });
  },
  async getNationalityStats() {
    return axios.get("https://steambanchecker.altervista.org/getNationalityStats.php").then((response) => {
      return response.data;
    });
  },
  async getStats() {
    return axios.get("https://steambanchecker.altervista.org/getStats.php").then((response) => {
      return response.data;
    });
  },
  async addUser(user) {
    return axios.post("https://steambanchecker.altervista.org/addPlayer.php", user).then((response) => {
      return response.data;
    });
  },
};
